@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  overflow: scroll;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

body {
  box-sizing: border-box;

  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #221b1a;
}

code {
  font-family: 'Poppins', sans-serif;
}
.button-gradient {
  margin-top: 20px;
  padding: 6px 18px;
  border: 2px solid transparent;
  background: linear-gradient(#221b1a, #221b1a) padding-box, linear-gradient(to right, red, orange) border-box;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.button-gradient:hover {
  background: linear-gradient(to bottom right, red, orange) border-box;
  scale: 1.1;
}
.content__project{
    border: 2px solid transparent;
  background: linear-gradient(#221b1a, #221b1a) padding-box, linear-gradient(to bottom right, #FF1E1E, #FF1E1E) border-box;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
}
.card{
  border: 2px solid transparent;
  background: linear-gradient(#221b1a, #221b1a) padding-box, linear-gradient(to bottom right, #FF1E1E, #FF9900) border-box;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
}